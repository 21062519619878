import { BiFilter } from 'react-icons/bi'
import { useSelector } from 'react-redux'

import {
  Box,
  Circle,
  HStack,
  Spacer,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'

import { Button } from '@/components/ui'
import { SearchInput } from '@/components/ui'
import { sopFilterCountSelector } from '@/redux/ui/uiSlice'

import { SOPsFilterDrawer } from '../sops-filter-drawer'

interface IProps {
  filter?: string
  setFilter?: React.Dispatch<React.SetStateAction<string>>
}

export const SOPsSearchFilterHeader = ({ filter, setFilter }: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const filterCount = useSelector(sopFilterCountSelector)

  return (
    <>
      <Box
        bgColor='white'
        borderBottom='1px solid #D5DCE4'
        boxShadow='lg'
        px='6'
        py='4'
        w='full'
      >
        <Stack
          alignItems='start'
          direction={{ base: 'column', md: 'row' }}
          justifyContent='start'
          m='auto'
        >
          <HStack spacing='2'>
            <Box
              alignItems='center'
              display='flex'
              flexDir='row'
              maxW='300px'
              w='full'
            >
              <SearchInput
                dataTestId='sopsPage_tableHeader_searchInput'
                onChange={(e) => setFilter(e.target.value)}
                placeholder='Search or Filter'
                value={filter}
              />
            </Box>
          </HStack>
          <Spacer />
          <Button
            dataTestId='sopsPage_tableHeader_filtersButton'
            h='40px'
            leftIcon={
              filterCount > 0 && (
                <Circle
                  bgColor='#fff'
                  color='#353849'
                  data-testid='sopsPage_tableHeader_filtersCount'
                  fontSize='11px'
                  h='20px'
                  minW='20px'
                  w='auto'
                >
                  {filterCount}
                </Circle>
              )
            }
            onClick={onOpen}
            px='6'
            rightIcon={<BiFilter size={24} />}
          >
            Filters
          </Button>
        </Stack>
      </Box>
      <SOPsFilterDrawer isOpen={isOpen} onClose={onClose} />
    </>
  )
}
