import React, { type MouseEvent } from 'react'

import { HiOutlineExclamation, HiOutlinePencil } from 'react-icons/hi'
import { useSelector } from 'react-redux'

import { Box, Circle } from '@chakra-ui/react'
import { motion } from 'framer-motion'

import { selectEditMode } from '@/redux/ui/uiSlice'

import { FacilityPointIProps } from '../../mapbox/types/types'
import { MENU_ITEM_SIZE } from '../constants'

interface CircularMenuItemIProps {
  facility: FacilityPointIProps
  position: { x: number; y: number }
  onClose: () => void
  onFacilityClick: (facilityId: string) => void
  openEditFacility?: (
    d: string,
    m: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => void
}

export const CircularMenuItem: React.FC<CircularMenuItemIProps> = ({
  facility,
  position,
  onClose,
  onFacilityClick,
  openEditFacility,
}) => {
  const isEditMode = useSelector(selectEditMode)
  const hasIncidents = facility.incidentCount > 0
  const numCount = facility.incidentCount > 999 ? '1k+' : facility.incidentCount

  const handleEditFacility = (
    m: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    openEditFacility?.(facility.id, m)
    onClose()
  }

  const handleFacilityClick = () => {
    if (facility.floorId) {
      onFacilityClick(facility.floorId)
    }
    onClose()
  }

  return (
    <motion.div
      animate={{
        x: position.x,
        y: position.y,
        scale: 1,
      }}
      exit={{ scale: 0 }}
      initial={{ scale: 0 }}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        zIndex: 2,
      }}
      transition={{
        type: 'spring',
        stiffness: 200,
        damping: 20,
      }}
    >
      <Circle
        _after={{
          content: '""',
          pos: 'absolute',
          borderRadius: '50%',
          h: '57px',
          w: '57px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderColor: hasIncidents ? '#fff' : '#4ad06c',
          borderWidth: '2px',
          borderStyle: 'solid',
        }}
        bg={hasIncidents ? '#D01030' : '#fff'}
        boxShadow={
          hasIncidents
            ? '0 2px 7px 0 rgba(189,25,25,0.5)'
            : '4px 0 14px 0 rgba(65,71,95,0.17)'
        }
        onClick={handleFacilityClick}
        pos='relative'
        size={`${MENU_ITEM_SIZE}px`}
      >
        {isEditMode && (
          <Circle
            _hover={{
              bgColor: '#d2d2d4',
            }}
            bgColor='#E5E8EE'
            boxShadow='0 2px 7px 0 rgba(50,121,199,0.5)'
            cursor='pointer'
            left='16px'
            onClick={handleEditFacility}
            pos='absolute'
            size='24px'
            top='58px'
            transition='ease-in-out'
            transitionDuration='300ms'
            transitionProperty='background-color'
            zIndex='overlay'
          >
            <HiOutlinePencil color='#3279C7' size='16px' />
          </Circle>
        )}
        <Box
          color={hasIncidents ? '#fff' : '#000'}
          fontSize='16px'
          fontWeight='semibold'
          letterSpacing='-0.4px'
        >
          {facility.shortName}
        </Box>

        {hasIncidents && (
          <>
            <Circle
              bg='#fff'
              bottom='-6px'
              boxShadow='0 2px 7px 0 rgba(189,25,25,0.5)'
              left='-8px'
              pos='absolute'
              size='28px'
              zIndex='overlay'
            >
              <HiOutlineExclamation color='#D01030' size={16} />
            </Circle>

            <Circle
              bg='#fff'
              bottom='-6px'
              boxShadow='0 2px 7px 0 rgba(189,25,25,0.5)'
              pos='absolute'
              right='-8px'
              size='28px'
              zIndex='overlay'
            >
              <Box
                color='#D01030'
                fontSize='14px'
                fontWeight='bold'
                letterSpacing='-0.4px'
              >
                {numCount}
              </Box>
            </Circle>
          </>
        )}
      </Circle>
    </motion.div>
  )
}
