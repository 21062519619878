import { useEffect, useState } from 'react'

import { useEditRemoveSOP } from '../hooks/useEditRemoveSOP'
import { useSOPsFilters } from '../hooks/useSOPsFilters'
import {
  ColumnDef,
  PaginationState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useDispatch, useSelector } from 'react-redux'

import {
  PageSizeSelector,
  Table,
  TableBody,
  TableFooter,
  TableHeader,
  TableSpinner,
} from '@/components/ui'
import { EditSOPModal, RemoveSOPModal } from '@/features/sop-crud'
import { StandardOperatingProceduresQuery } from '@/graphql/generated/operations'
import { selectSOPPageSize, setSOPPageSize } from '@/redux/ui/uiSlice'

import { RefetchSOPFnType, SOPsRowDataType } from '../types/types'
import { formatTableData } from '../utils/query'

const ROW_HEIGHT = 57
interface SOPsTableIProps {
  sops?: StandardOperatingProceduresQuery
  refetch: RefetchSOPFnType
  onRowClick: (id: string) => void
  columns: ColumnDef<SOPsRowDataType, any>[]
}

export const SOPsTable = ({
  columns,
  sops,
  refetch,
  onRowClick,
}: SOPsTableIProps) => {
  const dispatch = useDispatch()
  const total = sops?.standardOperatingProcedures?.totalCount || 0
  const pageSize = useSelector(selectSOPPageSize)
  const pageCount = Math.ceil(total / pageSize)
  const [isLoading, setIsLoading] = useState(false)
  const { filters } = useSOPsFilters()
  const [paginationState, setPaginationState] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSize,
  })

  const {
    sop,
    isEditSOPOpen,
    isRemoveSOPOpen,
    onEditSOPClose,
    onRemoveSOPClose,
    onSOPEdit,
    onSOPRemove,
  } = useEditRemoveSOP()

  const onPageResize = (pageIndex: number) => {
    dispatch(setSOPPageSize(pageIndex))
  }

  const tableData = formatTableData(sops, onSOPEdit, onSOPRemove)

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      pagination: paginationState,
    },
    manualPagination: true,
    pageCount,
    onPaginationChange: setPaginationState,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  useEffect(() => {
    setIsLoading(true)
    refetch({
      first: paginationState.pageIndex,
      last: paginationState.pageSize,
    }).then(() => {
      setIsLoading(false)
    })
  }, [paginationState, JSON.stringify(filters)])

  return (
    <>
      <Table tableContainerStyles={{ mb: '42px' }}>
        <TableHeader table={table} />
        {isLoading ? (
          <TableSpinner height={paginationState.pageSize * ROW_HEIGHT} />
        ) : (
          <TableBody onRowClick={onRowClick} rowModel={table.getRowModel()} />
        )}
        <TableFooter
          onGoToPage={table.setPageIndex}
          onNextPage={table.nextPage}
          onPreviousPage={table.previousPage}
          pageCount={pageCount}
          pageIndex={paginationState.pageIndex}
          pageSize={paginationState.pageSize}
          rightContent={
            <PageSizeSelector
              onClick={onPageResize}
              pageSize={table.getState().pagination.pageSize}
              setPageSize={table.setPageSize}
              title='SOPs per page'
            />
          }
          total={total}
        />
      </Table>
      {isRemoveSOPOpen && (
        <RemoveSOPModal
          isOpen={isRemoveSOPOpen}
          onClose={onRemoveSOPClose}
          refetch={refetch}
          sop={sop}
        />
      )}
      {isEditSOPOpen && (
        <EditSOPModal
          isOpen={isEditSOPOpen}
          onClose={onEditSOPClose}
          refetch={refetch}
          sop={sop}
        />
      )}
    </>
  )
}
