import React, { useMemo } from 'react'

import { FacilityPointIProps } from '../../mapbox/types/types'
import { MENU_ITEM_SIZE } from '../constants'
import { calculateRadius } from '../utils/circularPosition'
import { calculateLineCoordinates } from '../utils/lineCoordinates'
import { ConnectingLine } from './ConnectingLine'

interface ConnectingLinesIProps {
  orderedFacilities: FacilityPointIProps[]
}

export const ConnectingLines = ({
  orderedFacilities,
}: ConnectingLinesIProps) => {
  const dynamicRadius = calculateRadius(orderedFacilities.length)
  const svgSize = dynamicRadius * 2

  const lineCoordinates = useMemo(() => {
    return orderedFacilities.map((_, index) => ({
      coordinates: calculateLineCoordinates({
        index,
        totalItems: orderedFacilities.length,
        radius: dynamicRadius,
      }),
      index,
    }))
  }, [orderedFacilities, dynamicRadius])

  return (
    <svg
      style={{
        position: 'absolute',
        top: MENU_ITEM_SIZE / 2,
        left: MENU_ITEM_SIZE / 2,
        transform: 'translate(-50%, -50%)',
        width: svgSize,
        height: svgSize,
        pointerEvents: 'none',
        zIndex: 1,
      }}
    >
      {lineCoordinates.map(({ coordinates, index }) => (
        <ConnectingLine coordinates={coordinates} index={index} key={index} />
      ))}
    </svg>
  )
}
