import { Box, Flex, Text, Tooltip } from '@chakra-ui/react'

import { formatDateTimeWithAt } from '@/utils/formatDateTime'

const Separator = () => (
  <Box backgroundColor='#2D2E41' height='0.85em' mx={3} width='1px' />
)

export const NotificationSubtitle = ({
  timestamp,
  sourceName,
  facilityShortName,
  facilityName,
  fontSize = '12px',
}: {
  timestamp: string
  sourceName?: string
  facilityShortName?: string
  facilityName?: string
  fontSize?: number | string
}) => {
  return (
    <Flex
      alignItems='center'
      color='#2D2E41'
      data-testid='NotificationSubtitle:details'
      direction='row'
      fontSize={fontSize}
      fontWeight='bold'
      letterSpacing='-0.33px'
      opacity='0.8'
      w='full'
    >
      <Box whiteSpace='nowrap'>{formatDateTimeWithAt(timestamp)}</Box>
      {sourceName && (
        <>
          <Separator />
          <Text noOfLines={1}>{sourceName}</Text>
        </>
      )}
      {facilityShortName && (
        <>
          <Separator />
          <Tooltip label={facilityName}>
            <Text>{facilityShortName}</Text>
          </Tooltip>
        </>
      )}
    </Flex>
  )
}
