import React from 'react'

import { LineCoordinates } from '../types/types'

interface ConnectingLineIProps {
  coordinates: LineCoordinates
  index: number
}

export const ConnectingLine: React.FC<ConnectingLineIProps> = ({
  coordinates,
  index,
}) => {
  return (
    <line
      key={index}
      opacity={1}
      stroke='white'
      strokeWidth={2}
      x1={coordinates.startX}
      x2={coordinates.endX}
      y1={coordinates.startY}
      y2={coordinates.endY}
    />
  )
}
