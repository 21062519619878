import {
  CENTER_CLUSTER_SIZE,
  LINE_INNER_PADDING,
  LINE_OUTER_PADDING,
} from '../constants'
import { LineCalculationParams, LineCoordinates } from '../types/types'
import { calculateAngle } from './circularPosition'

export const calculateLineCoordinates = ({
  index,
  totalItems,
  radius,
}: LineCalculationParams): LineCoordinates => {
  const currentAngle = calculateAngle(index, totalItems)
  const angleInRadians = (currentAngle * Math.PI) / 180

  // Calculate start and end points using the angle
  const startX =
    radius +
    (CENTER_CLUSTER_SIZE / 2 + LINE_INNER_PADDING) * Math.cos(angleInRadians)
  const startY =
    radius +
    (CENTER_CLUSTER_SIZE / 2 + LINE_INNER_PADDING) * Math.sin(angleInRadians)

  const endX =
    radius +
    (radius - CENTER_CLUSTER_SIZE / 2 - LINE_OUTER_PADDING) *
      Math.cos(angleInRadians)
  const endY =
    radius +
    (radius - CENTER_CLUSTER_SIZE / 2 - LINE_OUTER_PADDING) *
      Math.sin(angleInRadians)

  return { startX, startY, endX, endY }
}
