import {
  BASE_ANGLE,
  BASE_RADIUS,
  MENU_ITEM_SIZE,
  MENU_STEPS_THRESHOLD,
  STEPS_COUNT_FACTOR,
} from '../constants'

export const calculateRadius = (facilitiesCount: number) => {
  const stepsCount =
    facilitiesCount > MENU_STEPS_THRESHOLD
      ? facilitiesCount
      : MENU_STEPS_THRESHOLD
  // Calculates the radius for the circle based on the number of facilities
  // STEPS_COUNT_FACTOR - the higher it is the more impact on the radius would number of facilities have
  return BASE_RADIUS + stepsCount * MENU_ITEM_SIZE * STEPS_COUNT_FACTOR
}

export const calculateAngle = (index: number, total: number): number => {
  // If less than 8 (MENU_STEPS_THRESHOLD) facilities, spread them as if there were 8 of them
  // This prevents them being spread out evenly
  // Instead it makes them go clockwise
  const stepsCount = total > MENU_STEPS_THRESHOLD ? total : MENU_STEPS_THRESHOLD
  const baseAngle = BASE_ANGLE
  const angleStep = 360 / stepsCount
  const currentAngle = baseAngle + index * angleStep

  return currentAngle
}

export const calculateCircularItemPosition = (index: number, total: number) => {
  const radius = calculateRadius(total)
  const currentAngle = calculateAngle(index, total)
  const angleInRadians = (currentAngle * Math.PI) / 180

  return {
    x: Math.cos(angleInRadians) * radius,
    y: Math.sin(angleInRadians) * radius,
    angle: currentAngle,
  }
}
