import { useSelector } from 'react-redux'

import { Flex, SimpleGrid, Text, Tooltip } from '@chakra-ui/react'

import { TableEditRemoveButton } from '@/features/sop-crud'
import { selectEditMode } from '@/redux/ui/uiSlice'

import { SOPsListItemFacilitiesData } from '../types/types'

export const FacilitiesCell = ({
  facilities,
  onRemove,
  onEdit,
}: {
  facilities: SOPsListItemFacilitiesData
  onRemove?: () => void
  onEdit?: () => void
}) => {
  const isEditMode = useSelector(selectEditMode)
  const names = facilities?.map((f) => f?.node?.name)

  return (
    <Flex alignItems='center' justifyContent='space-between'>
      <Tooltip
        hasArrow
        label={names?.join(', ')}
        openDelay={500}
        placement='auto'
      >
        <SimpleGrid minChildWidth='5px' minW='100px'>
          {facilities?.map((f) => {
            if (f?.node?.archived) {
              return
            }
            return (
              <Text
                bg='rgba(9, 30, 66, 0.03)'
                borderRadius='100px'
                color='#172B4D'
                data-testid='sopsPage_table_facilityCell'
                fontSize='12px'
                fontWeight='normal'
                isTruncated
                key={f?.node?.id}
                mr='4px'
                px='8px'
              >
                {f?.node?.name}
              </Text>
            )
          })}
        </SimpleGrid>
      </Tooltip>
      {isEditMode && (
        <TableEditRemoveButton
          handleOnEdit={onEdit}
          handleOnRemove={onRemove}
        />
      )}
    </Flex>
  )
}
