import { Circle } from '@chakra-ui/react'
import { motion } from 'framer-motion'

import { ClusterInfo } from '../../clusters/components/ClusterInfo'
import { CENTER_CLUSTER_SIZE } from '../constants'

interface CenteredClusterIProps {
  incidentCount: number
  facilityCount: number
}

export const CenteredCluster = ({
  incidentCount,
  facilityCount,
}: CenteredClusterIProps) => {
  return (
    <motion.div
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}
      initial={{ scale: 0 }}
    >
      <Circle
        bg={incidentCount > 0 ? '#D01030' : '#fff'}
        boxShadow={
          incidentCount > 0
            ? '0 2px 7px 0 rgba(189,25,25,0.5)'
            : '4px 0 14px 0 rgba(65,71,95,0.17)'
        }
        pos='relative'
        size={`${CENTER_CLUSTER_SIZE}px`}
      >
        <ClusterInfo
          facilityCount={facilityCount}
          hasIncidents={incidentCount > 0}
          marketIncidentsCount={0}
        />
      </Circle>
    </motion.div>
  )
}
