import { useEffect } from 'react'

import { usePermissions } from '@/hooks/usePermissions'
import { useToasts } from '@/hooks/useToasts'
import { SubmitHandler, useForm } from 'react-hook-form'
import { BiListUl } from 'react-icons/bi'
import { HiOutlineCheckCircle } from 'react-icons/hi'

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
} from '@chakra-ui/react'

import {
  Button,
  FormInputControl,
  FormInputSelectControl,
} from '@/components/ui'
import {
  useStandardOperatingProcedureEditQuery,
  useUpdateStandardOperatingProcedureMutation,
} from '@/graphql/generated/hooks'
import { SopType } from '@/graphql/generated/schemas'
import { OptionI, getFacilityOptions } from '@/utils/forms/optionBuilders'

import { SOP_ROLES } from '../utils/utils'

interface EditSOPModalIProps {
  isOpen: boolean
  onClose: () => void
  sop: {
    id: string
    name: string
  }
  refetch: () => void
}

interface FormInputsIProps {
  facilities?: OptionI[]
  role: OptionI<SopType>
}

export const EditSOPModal = ({
  isOpen,
  onClose,
  refetch,
  sop,
}: EditSOPModalIProps) => {
  const { shouldEnableUpdateSOP } = usePermissions()
  const { showSuccess, showError } = useToasts()
  const { handleSubmit, control, errors, setValue, formState } =
    useForm<FormInputsIProps>({
      mode: 'onChange',
    })
  const { isDirty } = formState

  const { data, loading: isLoading } = useStandardOperatingProcedureEditQuery({
    variables: {
      id: sop?.id,
    },
    fetchPolicy: 'network-only',
  })

  const [updateSOP, { loading: isUpdateSOPLoading }] =
    useUpdateStandardOperatingProcedureMutation()

  const facilityOptions = getFacilityOptions(data)

  const onSubmit: SubmitHandler<FormInputsIProps> = async (values) => {
    try {
      await updateSOP({
        variables: {
          input: {
            id: sop?.id,
            type: values.role.value,
            facilityIds: values.facilities.map(
              (facilityOption) => facilityOption.value
            ),
          },
        },
      })
      refetch?.()
      onClose()
      showSuccess(`Succesfully edited ${sop?.name}.`)
    } catch {
      showError()
    }
  }

  useEffect(() => {
    if (!isLoading && data) {
      const facilityIds = data?.standardOperatingProcedure?.facilityIds
      const facilities = facilityIds.map((id) =>
        facilityOptions.find((f) => f.value === id)
      )
      setValue('facilities', facilities, {
        shouldValidate: true,
      })
      const type = SOP_ROLES.find(
        (f) => f.value === data?.standardOperatingProcedure?.type
      )
      setValue('role', type, {
        shouldValidate: true,
      })
    }
  }, [isLoading])

  return (
    <Modal
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff' rounded='20px'>
        <ModalHeader>
          <Box alignItems='center' d='flex'>
            <BiListUl size='32px' />
            <Box ml={3}>
              <Box
                color='#353849'
                fontSize='26px'
                fontWeight='extrabold'
                letterSpacing='-0.4px'
                lineHeight='1'
              >
                Edit SOP
              </Box>
            </Box>
          </Box>
        </ModalHeader>
        <ModalCloseButton color='#000' opacity={0.4} />
        <ModalBody p={0}>
          {!isLoading ? (
            <Box px={6} py={2}>
              <form data-testid='EditSOPModal:form'>
                <Box mb={3}>
                  <FormInputControl
                    id='title'
                    isDisabled={true}
                    label='SOP Title'
                    value={sop.name}
                  />
                </Box>
                <Box mb={3}>
                  <FormInputSelectControl
                    closeMenuOnSelect={false}
                    control={control}
                    data-testid='EditSOPModal:facilities'
                    defaultValue={null}
                    id='facilities'
                    isClearable
                    isDisabled={isLoading}
                    isInvalid={!!errors.facilities}
                    isMulti
                    isSearchable
                    label='Facilities'
                    options={facilityOptions}
                    placeholder='Add facilities where this SOP applies'
                  />
                </Box>

                <Box mb={3}>
                  <FormInputSelectControl
                    control={control}
                    data-testid='CreateSOPModal:role'
                    defaultValue={null}
                    errorMessage={errors.role && 'Select a role'}
                    id='role'
                    isDisabled={isLoading}
                    isInvalid={!!errors.role}
                    label='Available To'
                    options={SOP_ROLES}
                    placeholder='Select the role that will have access to the SOP'
                    rules={{ required: true }}
                  />
                </Box>
              </form>
            </Box>
          ) : (
            <FormSkeleton />
          )}
        </ModalBody>
        <ModalFooter py={4}>
          <Button
            data-testid='EditSOPModal:cancel'
            mr={3}
            onClick={onClose}
            variant='secondary'
          >
            Cancel
          </Button>
          <Button
            data-testid='EditSOPModal:submit'
            isDisabled={!shouldEnableUpdateSOP || !isDirty}
            isLoading={isUpdateSOPLoading}
            leftIcon={<HiOutlineCheckCircle size='24px' />}
            loadingText='Saving changes...'
            onClick={handleSubmit(onSubmit)}
          >
            Save Changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const FormSkeleton = () => {
  return (
    <Box mb={3} px={6} py={2}>
      <Skeleton h='15px' maxW='120px' mb='9px' />
      <Skeleton h='45px' mb='20px' />
      <Skeleton h='15px' maxW='120px' mb='9px' />
      <Skeleton h='45px' mb='20px' />
      <Skeleton h='15px' maxW='120px' mb='9px' />
      <Skeleton h='45px' mb='20px' />
    </Box>
  )
}
