import { Text, TextProps, Tooltip } from '@chakra-ui/react'

interface TableCellIProps extends TextProps {
  label: string
  tooltipLabel?: string
  dataTestId?: string
}

export const TableCell = ({
  label,
  tooltipLabel = label,
  dataTestId,
  ...styles
}: TableCellIProps) => {
  return (
    <Tooltip
      hasArrow
      label={tooltipLabel}
      openDelay={500}
      placement='auto'
      shouldWrapChildren
    >
      <Text
        color='#5C6C7A'
        data-testid={dataTestId}
        fontSize='12px'
        fontWeight='normal'
        isTruncated
        {...styles}
      >
        {label}
      </Text>
    </Tooltip>
  )
}
