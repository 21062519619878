import React, { useRef } from 'react'

import { useLastData } from '@/hooks/useLastData'
import { useDispatch } from 'react-redux'
import { FixedSizeList } from 'react-window'

import { Box } from '@chakra-ui/react'

import { GlobalIncidentsQuery } from '@/graphql/generated/operations'
import { openIncidentDrawer, setIncident } from '@/redux/ui/uiSlice'

import { IncidentsListItem } from './IncidentsListItem'

interface IncidentsListIProps {
  data: GlobalIncidentsQuery
  isLoading: boolean
}

const FixedListDiv = ({
  children,
  ...props
}: {
  children: React.ReactNode
  [key: string]: unknown
}) => {
  return (
    <div data-testid='notificationsTab_activeIncidents_list' {...props}>
      {children}
    </div>
  )
}

export const IncidentsList = ({ data, isLoading }: IncidentsListIProps) => {
  const dispatch = useDispatch()
  const listRef = useRef(null)
  const lastData = useLastData(data)

  const incidents =
    lastData &&
    [...lastData?.incidents?.edges]
      ?.sort(
        (a, b) =>
          new Date(b?.node?.createdAt).valueOf() -
          new Date(a?.node?.createdAt).valueOf()
      )
      ?.map((i) => ({
        id: i?.node?.id,
        name: i?.node?.devices[0]?.name ?? 'Manual',
        title: i?.node?.name,
        dateTime: i?.node?.createdAt,
        facilityName: i?.node?.facility?.name,
        facility: i?.node?.facility?.shortName, // TODO - Update to reflect that this is the short name, and not a Facility object
        type: i?.node?.type,
        displayId: i?.node?.displayId,
        handleClick: () => openAlertIncidentDrawer(i?.node?.id),
      }))

  const openAlertIncidentDrawer = (id: string) => {
    dispatch(setIncident({ id }))
    dispatch(openIncidentDrawer())
  }

  return (
    <Box bgColor='#fff' data-testid='IncidentsList:container' h='full'>
      <Box bgColor='#fff' pos='relative'>
        {isLoading && !lastData ? (
          <Box fontWeight='semibold' mt='3' textAlign='center'>
            Loading...
          </Box>
        ) : incidents?.length > 0 ? (
          <FixedSizeList
            height={500}
            itemCount={incidents.length}
            itemData={incidents}
            itemSize={60}
            outerElementType={FixedListDiv}
            ref={listRef}
          >
            {IncidentsListItem}
          </FixedSizeList>
        ) : (
          <Box fontWeight='semibold' mt='3' textAlign='center'>
            List is Empty.
          </Box>
        )}
      </Box>
    </Box>
  )
}
