import type { RefObject } from 'react'

import type { MapRef } from 'react-map-gl'

import { MenuCoordinates, MenuPosition } from '../types/types'

interface MenuPositioningResultIProps {
  isOpen: boolean
  position: MenuPosition | null
  clusterCoordinates?: MenuCoordinates
}

/**
 * Calculates the position for the menu
 */
export const calculateMenuPosition = (
  facilitesCount: number,
  mapContainerRef: RefObject<HTMLDivElement>,
  mapRef: RefObject<MapRef>,
  coordinates?: MenuCoordinates
): MenuPositioningResultIProps => {
  if (facilitesCount < 1) {
    return {
      isOpen: false,
      position: null,
    }
  }

  const mapRect = mapContainerRef.current.getBoundingClientRect()
  const map = mapRef.current.getMap()

  const pixelPos = map.project([coordinates.longitude, coordinates.latitude])
  const currentX = pixelPos.x + mapRect.left
  const currentY = pixelPos.y + mapRect.top

  return {
    isOpen: true,
    position: { x: currentX, y: currentY },
  }
}
