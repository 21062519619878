import { useState } from 'react'

import { useDisclosure } from '@chakra-ui/react'

export interface SOPIProps {
  id: string
  name: string
}

export const useEditRemoveSOP = () => {
  const [sop, setSop] = useState<SOPIProps | null>(null)
  const {
    isOpen: isRemoveSOPOpen,
    onOpen: onRemoveSOPOpen,
    onClose: onRemoveSOPClose,
  } = useDisclosure()
  const {
    isOpen: isEditSOPOpen,
    onOpen: onEditSOPOpen,
    onClose: onEditSOPClose,
  } = useDisclosure()
  const onSOPEdit = async (sop: SOPIProps) => {
    setSop(sop)
    onEditSOPOpen()
  }
  const onSOPRemove = async (sop: SOPIProps) => {
    setSop(sop)
    onRemoveSOPOpen()
  }
  return {
    sop,
    isEditSOPOpen,
    isRemoveSOPOpen,
    onEditSOPClose,
    onRemoveSOPClose,
    onSOPEdit,
    onSOPRemove,
  }
}
