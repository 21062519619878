import { motion } from 'framer-motion'

import { BG_PADDING, CENTER_CLUSTER_SIZE } from '../constants'
import { calculateRadius } from '../utils/circularPosition'

interface BackgroundOverlayIProps {
  itemCount: number
}

export const BackgroundOverlay = ({ itemCount }: BackgroundOverlayIProps) => {
  const size = calculateRadius(itemCount) * 2 + BG_PADDING

  return (
    <motion.div
      animate={{
        scale: 1,
        opacity: 1,
      }}
      exit={{
        scale: 0.7,
        opacity: 0,
        transition: {
          duration: 0.3,
          ease: [0.4, 0, 0.2, 1],
        },
      }}
      initial={{
        scale: 0.5,
        opacity: 0,
      }}
      style={{
        position: 'absolute',
        top: -size / 2 + CENTER_CLUSTER_SIZE / 2,
        left: -size / 2 + CENTER_CLUSTER_SIZE / 2,
        width: size,
        height: size,
        transform: 'translate(-50%, -50%)',
      }}
    >
      <svg height={size} width={size}>
        <defs>
          <radialGradient cx='50%' cy='50%' id='menuGradient' r='50%'>
            <stop offset='0%' stopColor='rgba(18, 24, 38, 0.9)' />
            <stop offset='60%' stopColor='rgba(18, 24, 38, 0.9)' />
            <stop offset='100%' stopColor='rgba(18, 24, 38, 0)' />
          </radialGradient>
          <mask id='donut'>
            <rect fill='white' height='100%' width='100%' />
            <circle
              cx={size / 2}
              cy={size / 2}
              fill='black'
              r={CENTER_CLUSTER_SIZE / 2}
            />
          </mask>
        </defs>
        <circle
          cx={size / 2}
          cy={size / 2}
          fill='url(#menuGradient)'
          mask='url(#donut)'
          r={size / 2}
        />
      </svg>
    </motion.div>
  )
}
