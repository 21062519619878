import { useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { Box, Flex, Spinner } from '@chakra-ui/react'

import { SOPsTable, columns } from '@/features/sops-table'
import { useStandardOperatingProceduresQuery } from '@/graphql/generated/hooks'
import {
  OrderDirection,
  SopType,
  StandardOperatingProcedureOrderField,
} from '@/graphql/generated/schemas'
import {
  selectSOPInicdentDrawerState,
  setSOPIncidentId,
} from '@/redux/ui/uiSlice'

import { SOPIncidentHeader } from './SOPIncidentHeader'

const INITIAL_PAGE_SIZE = 25

export const SOPIncidentTable = () => {
  const dispatch = useDispatch()
  const { sopNameLike } = useSelector(selectSOPInicdentDrawerState)
  const filter = { nameLike: sopNameLike, type: SopType.Operator }

  const {
    data,
    loading: isLoading,
    refetch,
  } = useStandardOperatingProceduresQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: filter,
      first: 0,
      last: INITIAL_PAGE_SIZE,
      orderBy: [
        {
          direction: OrderDirection.Desc,
          field: StandardOperatingProcedureOrderField.CreatedAt,
        },
      ],
    },
  })

  const onItemClick = (id: string) => {
    dispatch(setSOPIncidentId({ id }))
  }

  const sopsCount = data?.standardOperatingProcedures.totalCount
  const sopList = data?.standardOperatingProcedures.edges ?? []
  const filteredColumns = columns.filter((col) => col.id !== 'type')

  const sopsTable = useMemo(
    () => (
      <SOPsTable
        columns={filteredColumns}
        onRowClick={onItemClick}
        refetch={refetch}
        sops={data}
      />
    ),
    [sopList]
  )

  return (
    <>
      <SOPIncidentHeader sopsCount={sopsCount} />

      <Box h='calc(100vh - 24px)' m='auto' overflow='auto' p='24px'>
        {isLoading && !data ? (
          <Flex justifyContent='center' mt='10'>
            <Spinner
              emptyColor='gray.300'
              size='lg'
              speed='0.65s'
              thickness='3px'
            />
          </Flex>
        ) : sopList?.length > 0 ? (
          sopsTable
        ) : (
          <Box textAlign='center'>List is empty</Box>
        )}
      </Box>
    </>
  )
}
