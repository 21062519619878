import { createColumnHelper } from '@tanstack/react-table'

import { Flex, Text, Tooltip } from '@chakra-ui/react'

import { SopType } from '@/graphql/generated/schemas'
import { formatDate, formatTimeDistance } from '@/utils/formatDateTime'
import { removeUnderscoreAndCapitalize } from '@/utils/formats'

import { SOPsRowDataType } from '../types/types'
import { FacilitiesCell } from './FacilitiesCell'
import { HeaderCell } from './HeaderCell'
import { TableCell } from './TableCell'

const columnHelper = createColumnHelper<SOPsRowDataType>()

export const columns = [
  columnHelper.accessor('name', {
    header: (info) => <HeaderCell info={info} label='SOP Name' />,
    cell: ({ getValue }) => (
      <TableCell
        color='#454F59'
        dataTestId='sopsPage_table_nameCell'
        fontWeight='semibold'
        label={getValue()}
      />
    ),
    enableSorting: true,
    size: 200,
  }),
  columnHelper.accessor('date', {
    header: (info) => <HeaderCell info={info} label='Date' />,
    cell: ({ getValue }) => (
      <TableCell
        dataTestId='sopsPage_table_dateCell'
        label={getValue()}
        tooltipLabel={`${formatDate(getValue())} • ${formatTimeDistance(
          getValue()
        )}`}
      />
    ),
    enableSorting: true,
    size: 200,
  }),
  columnHelper.accessor('creator', {
    header: (info) => <HeaderCell info={info} label='Creator' />,
    cell: ({ getValue }) => {
      const fullName = getValue()
        ? `${getValue()?.firstName} ${getValue()?.lastName}`
        : 'Unknown'
      return (
        <TableCell dataTestId='sopsPage_table_creatorCell' label={fullName} />
      )
    },
    enableSorting: true,
    size: 200,
  }),
  columnHelper.accessor('type', {
    id: 'type',
    header: (info) => <HeaderCell info={info} label='Assigned To' />,
    cell: ({ getValue }) => (
      <Tooltip
        hasArrow
        label={removeUnderscoreAndCapitalize(getValue())}
        openDelay={500}
        placement='auto'
        shouldWrapChildren
      >
        <Flex>
          <Text
            bg={getValue() === SopType.Operator ? '#7FABFF' : '#D0BCFF'}
            borderRadius='8px'
            color='#000000'
            data-testid='sopsPage_table_typeCell'
            fontSize='12px'
            fontWeight='normal'
            isTruncated
            px='8px'
            py='4px'
          >
            {removeUnderscoreAndCapitalize(getValue())}
          </Text>
        </Flex>
      </Tooltip>
    ),
    enableSorting: true,
    size: 200,
  }),
  columnHelper.accessor('facilities', {
    header: (info) => <HeaderCell info={info} label='Facilities' />,
    cell: ({ getValue }) => (
      <FacilitiesCell
        facilities={getValue().facilities}
        onEdit={getValue().onEdit}
        onRemove={getValue().onRemove}
      />
    ),
    size: 300,
  }),
]
