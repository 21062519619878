export const MENU_STEPS_THRESHOLD = 8
export const BASE_RADIUS = 70
export const STEPS_COUNT_FACTOR = 0.2
export const CENTER_CLUSTER_SIZE = 50
export const MENU_ITEM_SIZE = 60
export const LINE_INNER_PADDING = 20
export const LINE_OUTER_PADDING = 30
export const BG_PADDING = 200
export const BASE_ANGLE = -90

export const FLYTO_DURATION = 500
export const PAN_DURATION = 300
export const PAN_BUFFER = 20
export const PAN_COMPENSATION_FACTOR = 1.25
export const PAN_ENHANCEMENT_FACTOR = 1.1
