import { type MouseEvent, useEffect, useRef, useState } from 'react'

import { FeatureFlag, useFeatureFlags } from '@/hooks/useFeatureFlags'
import { useLocalState } from '@/hooks/useLocalState'
import ReactMapGL, { ViewStateChangeEvent } from 'react-map-gl'
import useSupercluster from 'use-supercluster'

import { Box } from '@chakra-ui/react'
import type { Feature } from 'geojson'

import {
  FacilityMarker,
  IncidentMarker,
  MapCluster,
} from '@/features/global-map'

import { ViewportStateIProps } from '..'
import { CircularMenu } from '../../circular-menu'
import { calculateMenuPosition } from '../../circular-menu/utils/menuPositioning'
import {
  ClusterPointIProps,
  FacilityPointIProps,
  MarketIncidentPointIProps,
  PointType,
} from '../types/types'
import { clusterOptions } from '../utils/clusters'
import {
  MAP_STYLE,
  MAX_ZOOM,
  calculateBoundingViewport,
  defaultViewport,
  isDefaultViewport,
} from '../utils/viewport'

export interface MapBoxIProps {
  features: Feature[]
  facilitiesPoints?: [number, number][]
  setDataBounds?: (bounds: string) => void
  height?: number
  width?: number
  children?: React.ReactNode
  onIncidentMarkerClick?: (id: string) => void
  onFacilityEditClick?: (
    id: string,
    m: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => void
  onFacilityClick?: (floorId: string) => void
}

export interface CircularMenuStateIProps {
  isOpen: boolean
  position: { x: number; y: number } | null
  facilities: FacilityPointIProps[]
}

export const MapBox = ({
  features,
  facilitiesPoints,
  height = 500,
  width = 800,
  setDataBounds,
  onIncidentMarkerClick,
  onFacilityClick,
  onFacilityEditClick,
}: MapBoxIProps) => {
  const mapRef = useRef(null)
  const mapContainerRef = useRef<HTMLDivElement>(null)
  const [viewport, setViewport] = useLocalState<ViewportStateIProps | null>(
    'viewport',
    defaultViewport
  )
  const [circularMenu, setCircularMenu] = useState<CircularMenuStateIProps>({
    isOpen: false,
    position: null,
    facilities: [],
  })
  const flags = useFeatureFlags()

  const isCircularMenuEnabled = flags?.[FeatureFlag.development]

  useEffect(() => {
    if (facilitiesPoints && isDefaultViewport(viewport)) {
      setViewport(calculateBoundingViewport(facilitiesPoints, width, height))
    }
  }, [facilitiesPoints])

  const handleContextMenu = (
    e: React.MouseEvent,
    facilities: FacilityPointIProps[],
    latitude: number,
    longitude: number
  ) => {
    e.preventDefault()
    e.stopPropagation()

    // Get the menu positioning information
    const menuResult = calculateMenuPosition(
      facilities.length,
      mapContainerRef,
      mapRef,
      { latitude, longitude }
    )

    // Initialize the circular menu
    setCircularMenu({
      isOpen: menuResult.isOpen,
      position: menuResult.position,
      facilities,
    })
  }

  const handleMapMove = (evt: ViewStateChangeEvent) => {
    if (!isDefaultViewport(evt?.viewState) && facilitiesPoints) {
      setViewport(evt?.viewState)
    }

    updateDataBounds()
  }

  const closeCircularMenu = () => {
    setCircularMenu((prev) => ({ ...prev, isOpen: false }))
  }

  const handleMapClick = () => {
    if (circularMenu.isOpen) {
      closeCircularMenu()
    }
  }

  const updateDataBounds = () => {
    if (mapRef.current && setDataBounds) {
      const bounds = mapRef.current.getMap().getBounds()
      setDataBounds(JSON.stringify(bounds.toArray()))
    }
  }

  const onClusterClick = (id: string, lat: number, lng: number) => {
    const expansionZoom = Math.min(
      supercluster.getClusterExpansionZoom(id),
      MAX_ZOOM
    )
    mapRef.current?.getMap().flyTo({
      center: { lng, lat },
      zoom: expansionZoom,
      duration: 400,
    })
  }

  const { clusters, supercluster } = useSupercluster({
    points: features,
    bounds: mapRef?.current?.getMap()?.getBounds()?.toArray()?.flat(),
    zoom: viewport?.zoom,
    options: clusterOptions,
  })

  return (
    <Box
      as='div'
      ref={mapContainerRef}
      style={{ height, width, position: 'relative', overflow: 'hidden' }}
    >
      <ReactMapGL
        {...viewport}
        doubleClickZoom={!circularMenu.isOpen}
        dragPan={!circularMenu.isOpen}
        dragRotate={!circularMenu.isOpen}
        keyboard={!circularMenu.isOpen}
        mapStyle={MAP_STYLE}
        mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_API_TOKEN}
        onClick={handleMapClick}
        onLoad={updateDataBounds}
        onMove={handleMapMove}
        ref={mapRef}
        scrollZoom={!circularMenu.isOpen}
        style={{ height, width }}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates
          const {
            cluster: isCluster,
            point_count: pointCount,
            incidentCount,
            facilityCount,
            type,
          }: ClusterPointIProps = cluster.properties

          if (isCluster) {
            const clusterFacilities = supercluster
              .getLeaves(cluster.id, Infinity)
              .map((leaf) => leaf.properties)
              .filter((prop) => prop.type === PointType.Facility)
            return (
              <MapCluster
                coordinates={{
                  latitude: latitude ?? 0,
                  longitude: longitude ?? 0,
                }}
                facilityCount={facilityCount}
                hasFacilityIncidents={incidentCount > 0}
                key={cluster.id}
                marketIncidentCount={pointCount - facilityCount}
                onClick={() => onClusterClick(cluster.id, latitude, longitude)}
                onContextMenu={(e) =>
                  handleContextMenu(e, clusterFacilities, latitude, longitude)
                }
              />
            )
          }

          // we have a single point to render
          if (type === PointType.Facility) {
            const facilityPoint: FacilityPointIProps = cluster.properties
            return (
              <FacilityMarker
                facility={facilityPoint}
                key={cluster.id}
                onPress={() => {
                  onFacilityClick?.(facilityPoint?.floorId)
                }}
                openEditFacility={(m) =>
                  onFacilityEditClick?.(facilityPoint?.id, m)
                }
              />
            )
          }
          if (type === PointType.MarketIncident) {
            const incidentMarketPoint: MarketIncidentPointIProps =
              cluster.properties
            return (
              <IncidentMarker
                incident={incidentMarketPoint}
                key={cluster.id}
                onClick={() => onIncidentMarkerClick?.(incidentMarketPoint?.id)}
              />
            )
          }
        })}

        {isCircularMenuEnabled &&
          circularMenu.position &&
          circularMenu.isOpen && (
            <CircularMenu
              facilities={circularMenu.facilities}
              isOpen={true}
              onClose={closeCircularMenu}
              onFacilityClick={onFacilityClick}
              openEditFacility={onFacilityEditClick}
              position={circularMenu.position}
            />
          )}
      </ReactMapGL>
    </Box>
  )
}
