import React, { useContext, useEffect } from 'react'

import { NotificationsContext } from '@/hooks/useOnNotification'

import { useGlobalIncidentsQuery } from '@/graphql/generated/hooks'
import {
  IncidentOrderField,
  IncidentStatus,
  OrderDirection,
} from '@/graphql/generated/schemas'
import { mixpanel } from '@/utils/analytics'

import { DrawerContainer } from '../../components/DrawerContainer'
import { IncidentsDrawerTitle } from './IncidentsDrawerTitle'
import { IncidentsList } from './IncidentsList'

export const IncidentsDrawer = ({ totalCount }: { totalCount: number }) => {
  const { data: newData } = useContext(NotificationsContext)
  const {
    data,
    loading: isLoading,
    refetch,
  } = useGlobalIncidentsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: {
        status: IncidentStatus.Active,
      },
      // Gets the 250 most recent active incidents
      first: 0,
      last: 250,
      orderBy: [
        {
          direction: OrderDirection.Desc,
          field: IncidentOrderField.CreatedAt,
        },
      ],
    },
  })

  useEffect(() => {
    isLoading
      ? mixpanel.time_event('Page Load: Incident Drawer')
      : mixpanel.track('Page Load: Incident Drawer', {
          number_of_incidents: totalCount,
        })
  }, [isLoading])

  useEffect(() => {
    if (newData?.notification) {
      refetch()
    }
  }, [newData])
  return (
    <DrawerContainer
      loading={isLoading}
      title={
        <IncidentsDrawerTitle
          title='Active Incidents'
          totalCount={totalCount}
        />
      }
    >
      <IncidentsList data={data} isLoading={isLoading} />
    </DrawerContainer>
  )
}
