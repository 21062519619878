/* eslint-disable no-console */
import { SOPIProps } from '../hooks/useEditRemoveSOP'

import { StandardOperatingProceduresQuery } from '@/graphql/generated/operations'
import {
  OrderDirection,
  StandardOperatingProcedureOrderField,
} from '@/graphql/generated/schemas'

import { SOPsRowDataType } from '../types/types'

const orderByMapping = {
  name: StandardOperatingProcedureOrderField.Name,
  date: StandardOperatingProcedureOrderField.CreatedAt,
  creator: StandardOperatingProcedureOrderField.CreatorId,
}

export const getOrderBy = (sortedColumn: {
  id?: string
  isSortedDesc?: boolean
}): {
  direction: OrderDirection
  field: StandardOperatingProcedureOrderField
}[] => {
  if (!sortedColumn?.id) {
    // Apply default sorting by date
    return [
      {
        direction: OrderDirection.Desc,
        field: StandardOperatingProcedureOrderField.CreatedAt,
      },
    ]
  } else {
    const direction = sortedColumn.isSortedDesc
      ? OrderDirection.Desc
      : OrderDirection.Asc
    const field = orderByMapping[sortedColumn.id]
    return [
      {
        direction,
        field,
      },
    ]
  }
}

export const getSortedColumn = (
  tableColumns: {
    id: string
    canSort: boolean
    isSorted?: boolean
    isSortedDesc?: boolean
  }[]
): { id?: string; isSortedDesc?: boolean } =>
  tableColumns
    .filter((c) => c.canSort && c.isSorted)
    .map((c) => ({
      id: c.id,
      isSortedDesc: c.isSortedDesc,
    }))?.[0] ?? {}

export const formatTableData = (
  sopData: StandardOperatingProceduresQuery,
  onEdit: (sop: SOPIProps) => void,
  onRemove: (sop: SOPIProps) => void
): SOPsRowDataType[] => {
  const sops =
    sopData?.standardOperatingProcedures?.edges?.map((e) => e.node) || []
  return sops.map((e) => {
    return {
      id: e?.id,
      name: e?.name,
      date: e?.createdAt,
      creator: e?.creator,
      type: e?.type,
      facilities: {
        facilities: e?.facilities?.edges,
        onEdit: () => onEdit({ id: e?.id, name: e?.name }),
        onRemove: () => onRemove({ id: e?.id, name: e?.name }),
      },
    }
  })
}
