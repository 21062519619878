import { FeatureFlag, useFeatureFlag } from '@/hooks/useFeatureFlags'
import { useToasts } from '@/hooks/useToasts'

import {
  IncidentDocument,
  useCreateIncidentActionMutation,
} from '@/graphql/generated/hooks'
import { IncidentActionType } from '@/graphql/generated/schemas'

export const useSendIncidentsToOntic = (incidentId: string) => {
  const { showSuccess, showError } = useToasts()

  const [createIncidentAction, { loading: isSendingToOntic }] =
    useCreateIncidentActionMutation()

  const isSalesEnv = useFeatureFlag(FeatureFlag.cannedVideo)

  const send = async () => {
    /**
     * isSalesEnv determins if we're in the sales environment or not
     * In the sales environmen, we only mimic sending the incident to Ontic and not actually send it
     */
    if (isSalesEnv) {
      showSuccess('Incident sent to Ontic successfully')
      return
    }
    try {
      await createIncidentAction({
        variables: {
          input: {
            incidentId,
            type: IncidentActionType.SendToOntic,
          },
        },
        refetchQueries: [
          {
            query: IncidentDocument,
            variables: {
              id: incidentId,
            },
          },
        ],
      })

      showSuccess('Incident sent to Ontic successfully')
    } catch (e) {
      showError(e.message)
    }
  }

  return {
    isSendingToOntic,
    send,
  }
}
