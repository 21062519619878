import { HeaderContext } from '@tanstack/react-table'
import { CgChevronDown, CgChevronUp } from 'react-icons/cg'

import { FlexboxProps, HStack, Text } from '@chakra-ui/react'

import { SOPsRowDataType } from '../types/types'

interface HeaderCellIProps {
  label: string
  justifyContent?: FlexboxProps['justifyItems']
  info: HeaderContext<SOPsRowDataType, any>
}

export const HeaderCell = ({ info, label }: HeaderCellIProps) => {
  return (
    <HStack
      color='rgba(71, 71, 71, 0.50)'
      fontSize='12px'
      fontWeight='medium'
      lineHeight='18px'
    >
      <Text>{label}</Text>
      {{
        asc: <CgChevronUp color='#5F5F5F' size='12px' />,
        desc: <CgChevronDown color='#5F5F5F' size='12px' />,
      }[info.header.column.getIsSorted() as string] ?? null}
    </HStack>
  )
}
